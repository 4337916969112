export const role = {
  viewer: "viewer",
  uploader: "uploader",
  editor: "editor",
};

export const rolePermissions = {
  viewer: 1,
  uploader: 3,
  editor: 7
};
